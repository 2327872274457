export const SET_TOKEN = 'SET_TOKEN';
export const SET_USER = 'SET_USER';
export const SET_USER_AVA_URL = 'SET_USER_AVA_URL';
export const SET_FL_NAME = 'SET_FL_NAME';
export const GET_BALANCE = 'GET_BALANCE';
export const LOG_OUT = 'LOG_OUT';
export const LOGIN_ERRORS = 'LOGIN_ERRORS';
export const CLEAR_LOGIN_ERRORS = 'CLEAR_LOGIN_ERRORS';

export const SWITCH_NAV = 'SWITCH_NAV';
export const SET_LOGIN_ERROR = "SET_LOGIN_ERROR"

export const REFRESH_TOKEN = "REFRESH_TOKEN";
