
import search from './search';
import balance from './balance';
import favorites from './favorites';
import files from './files';
import about from './about';
import chat from './chat';
import note from './note';

// import logout from './logout';

const menuItems = {
    items: [ search, balance, favorites, files,chat,note,about]
};
export default menuItems;
